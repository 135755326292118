.dashboard-cta-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
}

.dashboard-button-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.ongoing-games {
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    border-radius: .5em;
    padding: 2em;
}

.ongoing-games-container {
    display: flex;
    margin-top: 2em;
    flex-direction: column;
}

.dashboard-logo {
    display: flex;
    width: 10em;
    margin-bottom: 1em;
}