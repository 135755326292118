@import './ColorConstants.css';

.user-container {
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: center;
    align-items: end;
    background-color: rgba(25, 25, 26, .98);
    padding: 0px;
    width: 100%;
    height: 5em;
    margin: 0;
}

.user-input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 5em;
    padding-right: .5em;
}

.user-avatar-and-input-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 5em;
    justify-content: center;
    align-items: center;
}

.in-game-avatar {
    display: flex;
    width: 15%;
    height: auto;
    object-fit: contain;
}

.user-input {
    display: flex;
    flex: 1;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #7f8c8d;
    margin-right: 10px;
    margin-left: 1em;
}

.user-submit-text-btn {
    background-color: #6c584c;
    color: #ffffff;
    border: none;
    padding: .8em 1em;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 1em;
}

.game-menu-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--background-darken-super);
}

.in-game-menu-icon {
    display: flex;
    padding: .5em 0 .5em .5em;
    color: white;
    font-size: 1.5em;
}

@media screen and (max-width: 1100px) {
    .user-submit-text-btn {
        font-size: .9em;
    }
}