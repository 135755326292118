@import './../ColorConstants.css';
.character-name-avatar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    color: white;
    gap: 0;
}

.character-name-avatar-container h1 {
    margin-bottom: 0;
}

.avatar-cards-list-container {
    display: grid;
    width: 96%;
    grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
    gap: 1em;
    padding: 2em 2%;
    overflow: scroll;
    justify-content: center;
    align-items: start;
}

/* Force a minimum of two columns on small screens */
@media (max-width: 600px) {
    .avatar-cards-list-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .avatar-card-image {
        width: 8em;
    }

    .character-name-avatar-container h1 {
        font-size: 1.2em;
        margin-top: 1em;
        margin-bottom: .4em;
    }
}

.avatar-selected-modal-container {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--background-darken-extra);
    z-index: 5;
}

.avatar-selected-modal-content {
    display: flex;
    flex-direction: row;
    background: var(--background-darken-super);
    border-radius: 1em;
    border: 2px solid white;
    position: relative;
    max-width: 90%;
    align-items: stretch;
    overflow: clip;
}

@media screen and (max-width: 900px) {
    .avatar-selected-modal-content {
        flex-direction: column;
    }

    .avatar-card-image-selected {
        height: auto;
        width: 10em;
    }
}

.character-selection-screen {
    display: flex;
    width: 100%;
    height: 100%;
    color: white;
}

.avatar-selected-modal-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    align-items: stretch;
    margin: 1.5em 2.2em 1em 2.2em;
}

.avatar-selected-modal-x {
    position: absolute;
    top: .4em;
    right: .4em;
    cursor: pointer;
    font-size: 1.4em;
    z-index: 1;
}

.avatar-selected-modal-left {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 0;
    border-right: 1px solid white;
}

.create-character-button {
    background-color: white;
    color: var(--cta-text-color);
    border: none;
    padding: .3em .7em;
    font-size: 1em;
    cursor: pointer;
    border-radius: 1.5em;
    align-self: center;
    width: 100%;
}

.create-character-button:hover {
    background-color: #acafb0;
}

.create-character-button-premium-container {
    display: flex;
    position: relative;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    gap: .5em;
    width: 100%;
    background-color: white;
    color: var(--cta-text-color);
    border: none;
    padding: .3em .7em;
    font-size: 1em;
    cursor: pointer;
    border-radius: 1.5em;
    align-self: center;
}

.create-character-button-premium-container p {
    margin: 0;
}

.create-character-button-premium-container .premium-icon {
    position: absolute;
    right: 1em;
}

.avatar-card-image-selected-container .premium-icon {
    position: absolute;
    right: .5em;
    top: .5em;
    color: black;
    background: white;
}

.name-input-container {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .4em;
}

.name-input-container p {
    font-size: 1em;
    margin: 0;
    padding: 0;
}

.name-input-container input:focus {
    outline: none;
}