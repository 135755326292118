@import './ColorConstants.css';

.welcome-screen-container {
    display: flex;
    width: 100%;
    height: 100%;
    background: #6c584c;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.welcome-screen-content {
    display: flex;
    flex-direction: column;
    width: 30em;
    margin: 0 auto;
    padding: 2.2em;
    background-color: #a88367;
    color: #ecf0f1;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 0;
    border: #ced1d3 solid .5em;
}

.title-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    text-align: center;
    color: white;
    gap: .2em;
}

.title-container h1 {
    margin: 0 0 .1em 0;
}

.title-container p {
    padding: 0;
    margin: 0;
}

.welcome-screen-content h1 {
    margin: 0;
}

.welcome-screen-content p {
    margin: 0;
}

.code-join-game-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0;
    margin-top: 1em;
}

.code-join-game-container p {
    margin: 0;
}

.join-code-input-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.join-code-input-container input:focus {
    outline: none;
}

.join-code-input-container button {
    background-color: #6c584c;
    color: #ffffff;
    border: none;
    padding: .4em .4em;
    font-size: 1em;
    cursor: pointer;
    border-radius: .5em;
}

.firebase-auth-window {
    background-color: white;
    flex-direction: column;
    display: flex;
    border-radius: 1em;
    border: .2em solid gray;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 1em 0;
}

.firebase-auth-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.auth-container-close {
    align-self: end;
    background: none;
    border: none;
    margin-right: 1em;
    font-size: 1em;
    cursor: pointer;
    color: black
}

.welcome-cta-container {
    display: flex;
    flex-direction: column;

}

.legal-info-p-copyright-welcome-screen {
    position: absolute;
    right: .3em;
    bottom: .2em;
    color: white;
    font-size: 1em;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: end;
}

.legal-info-p-copyright-welcome-screen p {
    padding: 0;
    margin: 0;
}

.legal-info-terms-container-welcome {
    flex-direction: row;
    display: flex;
    color: white;
    font-size: 1em;
    text-decoration: none;
    gap: .4em;
}

.legal-info-p-copyright-welcome-screen .legal-info-item {
    font-size: 1em;
}

#anon-list {
    margin: 0 0 1em;
}

#anon-list-item {
    margin: 0;
}

#anon-sign-in-btn {
    display: flex;
    font-size: 12px;
    text-align: center;
    padding: 1em 2.8em;
}