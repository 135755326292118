/* src/css/AccountSettings.css */
.account-settings-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    text-align: center;
    color: white;
}

.account-settings-section h2 {
    margin: 0;
    padding: 0;
}