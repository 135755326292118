@import './ColorConstants.css';

.app-container {
  display: flex;
  justify-content: start;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: start;
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page-container {
  display: flex;
  width: 100%;
  height: 100%;
  background: #6c584c;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
}

.page-container-darkener {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-darken);
}

.page-container-extra-darkener {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-darken-extra);
}

.page-container-darkener h1 {
  color: white;
  margin-bottom: 1em;
}

.page-container-v2 {
  display: flex;
  width: 100%;
  height: 100%;
  background: url(../images/main-background.png);
  align-items: center;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page-content {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 90%;
  background-color: #a88367;
  color: #ecf0f1;
  border-radius: 10px;
  text-align: center;
  justify-content: start;
  align-items: center;
  gap: 0;
  border: #ced1d3 solid .5em;
}

.page-content-with-header {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  background-color: #a88367;
  color: #ecf0f1;
  text-align: center;
  justify-content: start;
  align-items: center;
  gap: 0;
  border-top: #ced1d3 solid .1em;
}