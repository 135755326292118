@import './ColorConstants.css';

.other-player-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    border: 4px solid var(--other-players-background2);;
}

.other-player-avatar-icon-container {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.player-avatar {
    width: 6em;
    height: auto;
    margin: 0;
    border: 1px solid black;
    background-color: var(--other-players-background);
}

.player-name {
    font-size: 1.2em;
    color: white;
    padding: .2em 0;
    margin: 0;
    width: 100%;
    background-color: var(--other-players-background2);
}

.highlight-player {
    background-color: rgba(255, 255, 255, 0.4);
}

.other-player-stats {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    background-color: var(--other-players-background2);
    justify-content: space-between;
    height: 100%;
    flex: 1;
    margin: 0;
    padding-left: .3em;
}

.current-player-stats {
    display: flex;
    flex-direction: row;
    background-color: var(--other-players-background2);
    width: 100%;
    justify-content: space-between;
    border-radius: .2em;
}

.other-players-container {
    display: flex;
    position: absolute;
    bottom: 5.5em;
    right: 1em;
    justify-content: center;
    gap: 2em;
}

.stat-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .2em;
}

.current-player-container {
    display: flex;
    flex-direction: column;
    width: 15%;
    height: auto;
    object-fit: contain;
    margin-left: .2em;
}

.current-player-stat-item {
    display: flex;
    flex-direction: column;
    padding: .2em;
    color: #e7e7e7;
}

.stat-item {
    color: #e7e7e7;
}

.in-game-current-avatar {
    width: 100%;
}

@media screen and (max-width: 1100px) {
    .player-name {
        font-size: .9em;
        padding: 0;
    }

    .player-avatar {
        width: 4em;
    }

    .stat-item {
        font-size: .7em;
    }

    .current-player-stats {
        font-size: .7em;
    }

    .current-player-container {
        width: 20%;
    }

    .current-player-stat-item {
        align-items: center;
        padding: 0;
    }

    .other-players-container {
        gap: .5em;
        right: .5em;
    }
}