.map-selection-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    color: #ecf0f1;
    width: 100%;
    gap: 1em;
}

.map-selection-container h2 {
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    /*margin: 2% 0 .5em 2%;*/
    padding: 0;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
}

.map-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.map {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    transform: scale(0.8);
    transition: all 0.5s ease;
}

.map img {
    max-width: 100%;
    max-height: 10em;
    cursor: pointer;
    border-radius: 0.3em;
    display: block;
}

.current-map {
    opacity: 1;
    transform: scale(1);
    z-index: 2;
    border: 0.3em solid white;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.previous-map,
.next-map {
    opacity: 0.5;
    transform: scale(0.8);
}

.arrow {
    font-size: 1em;
    color: #ecf0f1;
    cursor: pointer;
    z-index: 3;
}

.map img:hover {
    transform: scale(1.05);
}

.map-grid {
    display: grid;
    width: 96%;
    grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
    gap: 1em;
    margin: 0 2%;
    overflow: scroll;
    justify-content: center;
}

.single-map {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    cursor: pointer;
    max-width: 30em;
}

.selection-premium-icon {
    display: flex;
    position: absolute;
    top: .8em;
    right: .8em;
    color: var(--background-darken-super);
    font-size: .8em;
    background: white;
    border-radius: 2em;
    padding: .2em;
}

.single-map img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 1em;
}

.single-map p {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
}

.map-explanation {
    display: flex;
    flex-direction: column;
    width: 96%;
    padding: 1em;
    align-self: center;
    background: var(--background-darken-extra);
    border: solid 2px white;
    border-radius: 1em;
    gap: 1em;
}

.map-explanation h3 {
    margin: 0;
}

.map-explanation p {
    margin: 0;
}

.chosen-map-lobby {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.chosen-map-lobby img {
    width: 20em;
}

.chosen-map-lobby p {
    color: white;
    font-weight: bolder;
    font-size: 1.3em;
    margin: .6em 0;
}

@media screen and (max-width: 1100px) {
    .map-grid {
        display: grid;
        width: 96%;
        grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
        gap: 1em;
        margin: 0 2%;
        overflow: scroll;
        justify-content: center;
    }

    .single-map {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        max-width: 30em;
    }

    .map-selection-container h2 {
        margin: 1em .3em .4em;
        font-size: 1.2em;
    }

    .previous-map, .next-map {
        transform: scale(0.6);
    }

    .lobby-header-icon {
        font-size: 1.5em;
        top: .2em;
    }
}