.premium-upsell-modal-x {
    position: absolute;
    top: .7em;
    right: .7em;
    font-size: 1.3em;
    cursor: pointer;
}

.premium-upsell-modal-cta {
    background-color: white;
    color: black;
    border: none;
    padding: .6em .6em;
    font-size: 1em;
    cursor: pointer;
    border-radius: 1em;
}

.premium-perk-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    margin-bottom: 1em;
    gap: 1em;
}

@media (max-width: 768px) {
    .premium-perk-container {
        grid-template-columns: 1fr; /* Switch to 1 column on mobile */
    }
}

.premium-perk {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: .3em;
}

.modal-content .perk-text {
    display: flex;
    margin: 0;
    font-size: 1em;
}

.modal-content .perk-subtext {
    display: flex;
    margin: 0;
    font-size: .7em;
}

.premium-perk-image {
    display: flex;
    object-fit: cover;
    height: auto;
    aspect-ratio: 5 / 3;
    width: 100%;
    max-width: 20em;
    border-radius: 1em;
    border: 2px solid silver;
}

#premium-membership-title {
    margin-bottom: 0;
}

#premium-membership-subtitle {
    margin-top: .5em;
    margin-bottom: 1em;
}