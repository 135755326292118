.cta {
    background-color: white;
    color: var(--cta-text-color);
    border: none;
    padding: .8em 1em;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 1em;
}

.cta:hover {
    background-color: white;
    color: var(--cta-text-color);
}

.dashboard-cta {
    background-color: white;
    color: var(--cta-text-color);
    border: none;
    padding: .8em 1em;
    font-size: 1.2em;
    font-weight: bolder;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 1em;
}

.dashboard-cta.secondary {
    background: none;
    background-color: var(--background-darken);
    border: solid 3px white;
    font-weight: bold;
    color: white;
}

.dashboard-cta.secondary.container {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: .8em 1em;
    gap: .3em;
}

.dashboard-cta.premium {
    background: none;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: white;
    display: flex;
    font-size: 1em;
}

.premium-icon {
    display: flex;
    color: var(--background-darken-super);
    font-size: .8em;
    background: white;
    border-radius: 2em;
    padding: .2em;
}


.header-cta {
    background-color: white;
    color: #6c584c;
    border: none;
    padding: .6em .6em;
    font-size: 1em;
    cursor: pointer;
    border-radius: 1em;
}