.remaining-points-text {
    font-size: 1.1em;
}

.create-button:disabled {
    background-color: #e4e4e4;
    color: #ababab;
}

.create-button:disabled:hover {
    background-color: #e4e4e4;
}

.create-button {
    margin-top: 2em;
    background-color: white;
    color: var(--cta-text-color);
    border: none;
    font-weight: bold;
    padding: 1em 1em;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 1.5em;
}

.create-button:hover {
    background-color: white;
    color: var(--cta-text-color);
}

.character-name-avatar-container {
    gap: 0;
}

.skills-row {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.lobby-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .2em;
}

.lobby-avatar-premium {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .2em;
}

.lobby-avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5em;
    background: white;
    border-radius: 50%;
    width: 5em;
}

.lobby-avatar-container.premium {
    background: gray;
}

.placeholder {
    background: white;
}

.lobby-avatar-container img {
    display: flex;
    height: 5em;
}

.lobby-avatar-image-container {
    display: flex;
    background: white;
    border-radius: 50%;
}

.lobby-avatar p {
    margin: 0;
    font-size: 1.2em;
    color: white;
}

.players-list-lobby-container {
    display: flex;
    flex-direction: row;
    gap: 4em;
    margin-top: 2em;
}

.player-lobby-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: white;
    height: 100%;
    align-self: center;
}

.create-game-cta-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.clipboard-alert {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1b1b1b;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 2s forwards;
    z-index: 100000;
}

@media screen and (max-width: 1100px) {
    .players-list-lobby-container {
        gap: 1em;
    }

    .lobby-avatar-container {
        height: 4em;
        width: 4em;
    }

    .lobby-avatar-container img {
        display: flex;
        height: 4em;
    }

    .lobby-avatar p {
        font-size: .9em;
    }

    .create-button {
        margin-top: 2em;
        border: none;
        padding: .7em 1em;
        font-size: 1em;
        cursor: pointer;
        border-radius: 1.5em;
    }
}