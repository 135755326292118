@import './ColorConstants.css';

.game-narration-container {
    font-size: 1.5em;
    width: 100%;
    max-width: 1200px;
    background-color: rgba(25, 25, 26, .98);
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: start;
    align-items: start;
    max-height: 60vh;
    overflow: scroll;
    margin: 0;
    padding: 0;
    padding-bottom: 1em;
    border: white solid .1em;
}

.game-narration-content {
    display: flex;
    color: #ecf0f1;
    text-align: start;
    padding: 1em;
    margin: 0;
    white-space: pre-wrap;

}

.game-narration-arrows-container {
    display: flex;
    width: 98%;
    justify-content: right;
    gap: .2em;
}

.narration-arrow {
    color: #ecf0f1;
    font-size: 1.3em;
}

.disabled-arrow {
    color: #676869;
}