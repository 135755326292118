.lobby-header-icon {
    position: absolute;
    left: 0;
    top: 0;
    margin: .5em;
    color: white;
    font-size: 2em;
}

.avatar-card-container {
    display: flex;
    position: relative;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 1em;
    background: var(--background-darken-extra);
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.avatar-card-container h2 {
    margin: 0;
    font-size: .8em;
}

.avatar-card-image {
    width: 12em;
}

.avatar-card-image-selected-container {
    display: flex;
    position: relative;
    width: 100%;
    align-self: flex-end;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-end;
    overflow: clip;
}

.avatar-card-image-selected {
    height: 18em;
    object-fit: contain;
}

.avatar-card-skills-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    gap: .5em;
    width: 100%;
    margin-top: 0.6em;
    margin-bottom: 0.3em;
}

.avatar-card-skills-column {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.avatar-card-info-table td {
    border: 1px solid white;
    font-size: .8em;
    padding: .1em 0;
    text-align: center;
}

.avatar-card-info-table {
    border-spacing: 0;
    border-top: 1px solid white;
    align-self: flex-end;
    width: 100%;
}
