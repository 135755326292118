@import './ColorConstants.css';
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--background);
    padding: 2em;
    max-width: 98%;
    max-height: 98%;
    overflow: scroll;
    border-radius: 1em;
    text-align: center;
    color: white;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 1em;
}

.modal-content p {
    margin: 1em 0 0 0;
    font-size: .8em;
}

.leave-modal-buttons {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    gap: 1em;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
}

.game-menu-modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.game-menu-modal-button {
    background-color: white;
    color: var(--cta-text-color);
    border: none;
    padding: .6em 1em;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 1em;
}

.modal-confirm-button, .modal-cancel-button {
    padding: 0.5em 1em;
    border: none;
    cursor: pointer;
    font-size: 1em;
    border-radius: 0.25em;
}

.modal-confirm-button {
    background-color: white;
    color: black;
}

.modal-cancel-button {
    background-color: var(--background);
    border: 2px solid white;
    color: white;
}