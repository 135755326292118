.saved-games-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-behavior: auto;
    overflow: scroll;
    height: 100%;
    width: 100%;
}

.saved-games-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.saved-games-list {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: .5em;
    margin-bottom: 1em;
}

#saved-games-header {
    color: white;
}

.saved-game-item {
    display: flex;
    flex-direction: row;
    background: var(--background-darken-extra);
    border-radius: 2em;
    border: 2px solid white;
    overflow: clip;
    color: white;
}

.avatar-preview-image-saved-game {
    height: auto;
    width: 100%;
}

.saved-game-map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: white 1px solid;
    width: 30%;
    height: 100%;
}

.saved-game-map-container img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.saved-game-players-container {
    display: flex;
    flex-direction: column;
    margin: 0 1em;
    width: 60%;
}

.saved-game-players-container h3 {
    margin: .5em;
}

.saved-games-players-list {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.player-avatar-container {
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
}

.player-avatar-container p {
    margin: .4em 0;
    text-align: center;
    font-size: 1em;
}

.saved-game-last-played-container {
    display: flex;
    flex-direction: column;
    border-left: 1px white solid;
    align-items: center;
    justify-content: center;
    width: 10%;
}

.saved-game-last-played-container p {
    margin: 0 1em;
    text-align: center;
    font-size: .8em;
}

@media screen and (max-width: 800px) {
    .saved-games-list {
        width: 98%;
    }

    .saved-games-players-list {
        gap: .5em;
    }

    .saved-game-players-container {
        margin: 0 .2em;
    }

    .saved-game-players-container h3 {
        font-size: .8em;
    }

    .saved-game-last-played-container {
        width: 20%;
    }

    .player-avatar-container p {
        font-size: .8em;
        text-overflow: ellipsis;
    }

    .saved-game-last-played-container p {
        font-size: .7em;
        margin: 0 .3em;
    }
}