:root {
    --background: #1a1a1a;
    --narration-background: #CC1a1a1a;

    --brown-background: #3c312b;
    --brown-forground: #6c584c;
    --other-players-background: rgba(59, 59, 59, 0.8);
    --other-players-background2: rgba(0, 0, 0, 0.6);
    --background-darken: rgba(0, 0, 0, 0.2);
    --background-darken-extra: rgba(0, 0, 0, 0.5);
    --background-darken-super: rgba(0, 0, 0, 0.9);

    --cta-text-color: #1a1a1a;
}