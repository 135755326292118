.skill {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: .5em;
    padding: 0;
    gap: .5em;
    font-size: .7em;
    width: 100%;
}

.chosen-avatar-skill {
    font-size: .9em;
}

.skill-icon {
    margin-right: 1em;
}

.skill-name {
    width: 100%;
    flex: 2;
}

.skill-value {
    text-align: center;
    font-size: 1.1em;
}

.set-skill-value-container {
    display: flex;
    flex: 1;
    gap: .5em;
    justify-content: end;
}

.arrow {
    cursor: pointer;
    color: white;
}

.arrow:hover {
    color: #d35400;
}

.character-skills-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.skills-customize-container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-self: end;
    gap: 2em;
    margin-bottom: .8em;
    font-size: .8em;
    width: 100%;
    justify-content: end;
}

.skills-customize-icon {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    text-align: end;
    z-index: 1;
}

#skills-edit-remaining-points {
    display: flex;
    margin: .4em 0 1em 0;
    padding: 0;
    font-size: .75em;
}

.skills-customize-container p {
    margin: 0;
    padding: 0;
}

.avatar-selected-skills-container {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    border-radius: 1em;
    position: relative;
    padding: 1em 1em .5em 1em;
}

.avatar-selected-skills-container h3 {
    display: flex;
    align-self: center;
    margin: 0 0 .2em 0;
    font-size: 1em;
    padding: 0;
}

.avatar-selected-skills-container p {
    align-self: center;
    margin: 0;
    padding: 0;
}

.avatar-selected-skills-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: .5em;
}